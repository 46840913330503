import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { APIEndpoints } from "./APIEndpoints";
import axios from "axios";
import Cookies from "universal-cookie";
import {
  setLocalStorageWithExpiration,
  getLocalStorageWithExpiration,
  removeLocalStorageItem
} from "./localStorageUtil";

function Task() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [formTask, setFormTask] = useState([]);

  const cookie = new Cookies();
  const billableSubmittedTask = cookie.get("clickedTasks")
    ? cookie.get("clickedTasks").length
    : 0;

  // const fetchData = async () => {
  //   if (getLocalStorageWithExpiration("task-data")) {
  //     setIsLoading(true);
  //     const cookieData = getLocalStorageWithExpiration("task-data");
  //     setData(cookieData);
  //     setIsLoading(false);
  //   } else {
  //     try {
  //       setIsLoading(true);
  //       const cookie = new Cookies();
  //       const token = cookie.get("logindata");
  //       const { access_token, sf_id } = token;
  //       const response = await axios.get(`${APIEndpoints.ALL_TASK}${sf_id}`);
  //       setData(response.data);
  //       console.log(response.data);
  //       // Set Data in Cookie for 30 minutes
  //       setLocalStorageWithExpiration("task-data", response.data, 30);
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setIsLoading(false);
  //     }
  //   }
  // };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const cookie = new Cookies();
      const token = cookie.get("logindata");
      const { access_token, sf_id } = token;
      const response = await axios.get(`${APIEndpoints.ALL_TASK}${sf_id}`);
      setData(response.data);
      if (getLocalStorageWithExpiration("task-data")) {
        const cookieData = getLocalStorageWithExpiration("task-data");
        removeLocalStorageItem("task-data", 0);
      }
      // Set Data in Cookie for 30 minutes
      setLocalStorageWithExpiration("task-data", response.data, 30);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header />

      {isLoading ? (
        <div className="">
          <div className="card-body text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container custom-container my-5">
          <div>
            <p className="mb-3 font-weight-bold px-2">
              Please complete tasks to ensure timely payment and full
              compliance. Only open tasks will display here
            </p>
          </div>

          {(data.missing_eims && Object.keys(data.missing_eims).length > 0) ||
          (data.missing_sf_sessions &&
            Object.keys(data.missing_sf_sessions).length > 0) ? (
            <>
              <Link to="/session-issues" className="linkbox">
                <div className="box position-relative d-flex justify-content-between p-3 mb-3 rounded">
                  {(data.missing_eims &&
                    Object.keys(data.missing_eims).length > 0) ||
                  (data.missing_sf_sessions &&
                    Object.keys(data.missing_sf_sessions).length > 0) ? (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                      {Object.keys(data.missing_eims).length +
                        Object.keys(data.missing_sf_sessions).length}
                    </span>
                  ) : (
                    ""
                  )}
                  <div>
                    <h5 className="ms-2">
                      {(data.missing_eims &&
                        Object.keys(data.missing_eims).length > 0) ||
                      (data.missing_sf_sessions &&
                        Object.keys(data.missing_sf_sessions).length > 0) ? (
                        <span>
                          {Object.keys(data.missing_eims).length +
                            Object.keys(data.missing_sf_sessions).length}{" "}
                          Session(s) Not Fully Reconciled
                        </span>
                      ) : (
                        "No Sessions"
                      )}
                    </h5>
                    <p className="ms-2">
                      View sessions that have not been logged properly on the
                      EIMs/ TA System{" "}
                    </p>
                  </div>
                  <div className="my-auto">
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </Link>
            </>
          ) : (
            ""
          )}

          {data.not_billable && Object.keys(data.not_billable).length > 0 ? (
            <>
              <Link to="/non/billing" className="linkbox">
                <div className="box position-relative d-flex justify-content-between p-3 mb-3 rounded">
                  {data.not_billable &&
                  Object.keys(data.not_billable).length > 0 ? (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                      {Object.keys(data.not_billable).length}
                    </span>
                  ) : (
                    ""
                  )}
                  <div>
                    <h5 className="ms-2">
                      {data.not_billable &&
                      Object.keys(data.not_billable).length > 0 ? (
                        <span>
                          {Object.keys(data.not_billable).length} Session(s)
                          with Missing Signature
                        </span>
                      ) : (
                        "No session with missing signature"
                      )}
                    </h5>
                    <p className="ms-2">
                      View sessions that are missing parent/Caregiver signature.
                    </p>
                  </div>
                  <div className="my-auto">
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </Link>
            </>
          ) : (
            ""
          )}

          {data.billable &&
          Object.keys(data.billable).length - billableSubmittedTask > 0 ? (
            <>
              <Link to="/billing" className="linkbox">
                <div className="box position-relative d-flex justify-content-between p-3 mb-3 rounded">
                  {data.billable && Object.keys(data.billable).length > 0 ? (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                      {Object.keys(data.billable).length -
                        billableSubmittedTask}
                    </span>
                  ) : (
                    ""
                  )}
                  <div>
                    <h5 className="ms-2">
                      {data.billable &&
                      Object.keys(data.billable).length -
                        billableSubmittedTask >
                        0 ? (
                        <span>
                          Confirm Your Billing for{" "}
                          {Object.keys(data.billable).length -
                            billableSubmittedTask}{" "}
                          Service(s)
                        </span>
                      ) : (
                        "No services for billing"
                      )}
                    </h5>
                    <p className="ms-2">
                      After the close of each month, you must confirm your
                      billing for each child
                    </p>
                  </div>
                  <div className="my-auto">
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </Link>
            </>
          ) : (
            ""
          )}

          {/* <div className='box d-flex justify-content-between p-3 mb-3 rounded'>
          <div>
            <h5 className='ms-2'>Training Sessions Required</h5>
            <p className='ms-2'>You are required to take a short training session and test to continue servicing </p>
          </div>
          <div className='my-auto'>
            <i className="fa-solid fa-arrow-right"></i>
          </div>
        </div> */}

          {/*{data.form25task && data.form25task.length > 0 ? (
            <>
              <Link to="/form-25/task" className="linkbox">
                <div className="box position-relative d-flex justify-content-between p-3 mb-3 rounded">
                  {data.form25task && data.form25task.length > 0 ? (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                      {data.form25task.length}
                    </span>
                  ) : (
                    ""
                  )}
                  <div>
                    <h5 className="ms-2">
                      {data.form25task && data.form25task.length > 0 ? (
                        <span>
                          {data.form25task.length} Form 25 needs to be completed
                        </span>
                      ) : (
                        "No Form 25 Tasks"
                      )}
                    </h5>
                    <p className="ms-2">
                      You are required to complete the form 25 task.{" "}
                    </p>
                  </div>
                  <div className="my-auto">
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </Link>
            </>
          ) : (
            ""
          )}*/}

          {data.form13task && data.form13task.length > 0 ? (
            <>
              <Link to="/form13" className="linkbox">
                <div className="box position-relative d-flex justify-content-between p-3 mb-3 rounded">
                  {data.form13task && data.form13task.length > 0 ? (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                      {data.form13task.length}
                    </span>
                  ) : (
                    ""
                  )}
                  <div>
                    <h5 className="ms-2">
                      {data.form13task && data.form13task.length > 0 ? (
                        <span>{data.form13task.length} Open Form 13(s)</span>
                      ) : (
                        "No Form 13 Tasks"
                      )}
                    </h5>
                    <p className="ms-2">
                      You are required to complete the form 13 task.{" "}
                    </p>
                  </div>
                  <div className="my-auto">
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </Link>
            </>
          ) : (
            ""
          )}

          {data.trainings && data.trainings.length > 0 ? (
            <>
              <Link to="/quiz-type" className="linkbox">
                <div className="box position-relative d-flex justify-content-between p-3 mb-3 rounded">
                  {data.trainings && data.trainings.length > 0 ? (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                      {data.trainings.length}
                    </span>
                  ) : (
                    ""
                  )}
                  <div>
                    <h5 className="ms-2">
                      {data.trainings && data.trainings.length > 0 ? (
                        <span>
                          {data.trainings.length} Therapist Training(s)
                        </span>
                      ) : (
                        "No Missing EIMS"
                      )}
                    </h5>
                    <p className="ms-2">Perform Therapist Training here</p>
                  </div>
                  <div className="my-auto">
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </Link>
            </>
          ) : (
            ""
          )}

          {data.contact_attempt_services &&
          data.contact_attempt_services.length > 0 ? (
            <>
              <Link to="/parent-contacts-task" className="linkbox">
                <div className="box position-relative d-flex justify-content-between p-3 mb-3 rounded">
                  {data.contact_attempt_services &&
                  data.contact_attempt_services.length > 0 ? (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                      {data.contact_attempt_services.length}
                    </span>
                  ) : (
                    ""
                  )}
                  <div>
                    <h5 className="ms-2">
                      {data.contact_attempt_services &&
                      data.contact_attempt_services.length > 0 ? (
                        <span>
                          {data.contact_attempt_services.length} Parent Contact
                          Attempt(s)
                        </span>
                      ) : (
                        "No Parent Contact Attempt"
                      )}
                    </h5>
                    <p className="ms-2">
                      Perform to complete parent contact attempt here
                    </p>
                  </div>
                  <div className="my-auto">
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </Link>
            </>
          ) : (
            ""
          )}

          {/* {data.missing_sf_sessions && data.missing_sf_sessions.length > 0 ? (
            <>
              <Link to="/session-missing" className="linkbox">
                <div className="box position-relative d-flex justify-content-between p-3 mb-3 rounded">
                  {data.missing_sf_sessions &&
                  data.missing_sf_sessions.length > 0 ? (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                      {data.missing_sf_sessions.length}
                    </span>
                  ) : (
                    ""
                  )}
                  <div>
                    <h5 className="ms-2">
                      {data.missing_sf_sessions &&
                      data.missing_sf_sessions.length > 0 ? (
                        <span>
                          {data.missing_sf_sessions.length} Sessions Missing In
                          TA
                        </span>
                      ) : (
                        "Sessions Missing In TA"
                      )}
                    </h5>
                    <p className="ms-2">Perform Sessions Missing In TA</p>
                  </div>
                  <div className="my-auto">
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              </Link>
            </>
          ) : (
            ""
          )} */}
        </div>
      )}
    </>
  );
}

export default Task;

// onClick={(e) => {
//   navigate("/parent-contacts", {
//     state: {
//       service_id: descriptionOptions.child_service_id,
//       childID: childID,
//       therapistServiceId: therapistServiceId,
//     },
//   });
// }}
