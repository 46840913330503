import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import './App.css';
import Task from './components/Task';
import Children from './components/Child';
import ChildDetail from './components/ChildDetail';
import SessionHistory from './components/SessionHistory';
import Availability from './components/Availability';
import AvailabilityAreas from './components/AvailabilityAreas';
import ChildForm from './components/ChildForm';
import Login from './components/Login';
import PrivateComponent from './components/PrivateComponent';
import Billable from './components/Billable';
import BillableDetail from './components/BillableDetail';
import TherapistAvailability from './components/TherapistAvailability';
import TherapistRegions from './components/TherapistRegions';
import EIMSForm from './components/EIMSForm';
import FilePasswordFrom from './components/FilePasswordFrom';
import UploadFile from './components/UploadFile';
import NonBillable from './components/NonBillable';
import SendSignatureLink from './components/SendSignatureLink';
import SignatureEmail from './components/SignatureEmail';
import Form25 from './components/Form25';
import ThankYou from './components/ThankYou';
import Form25Task from './components/Form25Task';
import UploadOnboardingDoc from './components/UploadOnboardingDoc';
import UploadDocument from './components/UploadDocument';
import UniversalQuiz from './components/UniversalQuiz';
import QuizTypes from './components/QuizTypes';
import FwpQuiz from './components/FwpQuiz';
import EIMSTask from './components/EIMSTask';
import TrainingsVideo from './components/TrainingsVideo';
import DemoPDF from './components/DemoPDF';
import ChildContacts from './components/ChildContacts';
import CreateForm25 from './components/CreateForm25';
import PolicyProcedure from './components/PolicyProcedure';
import PolicyProcedureSign from './components/PolicyProcedureSign';
import ParentContactForm from './components/ParentContactForm';
import ParentContactAttemptTask from './components/ParentContactAttemptTask';
import ChildContactSelection from './components/ChildContactSelection';
import DraftForm25 from './components/DraftForm25';
import SessionMissingTA from './components/SessionMissingTA';
import SessionIssues from './components/SessionIssues';
import FilledEIMSForm from './components/FilledEIMSForm';
import ConsentSignature from './components/ConsentSignature';
import ConsentContacts from './components/ConsentContacts';
import Form25Download from './components/Form25Download';
import Form13Task from './components/Form13Task';
import NotFound from "./components/NotFound";
import PayStub from './components/PayStub';
import UnpaidSessions from './components/UnpaidSessions';
import ProcessedSessions from './components/ProcessedSessions';
import BillingConfirmed from './components/BillingConfirmed';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route exact path={'/login'} element={<Login />} />
          <Route exact path={'/re/signature/:sessionId/:signature_request_record'} element={<SignatureEmail />} />
          <Route exact path={'/thank-you'} element={<ThankYou />} />
          <Route exact path={'/policy-procedure/:childId/:childContactId'} element={<PolicyProcedure />} />
          <Route exact path={'/policy-procedure-sign'} element={<PolicyProcedureSign />} />
          <Route exact path={'/select-contact'} element={<ChildContactSelection />} />
          <Route exact path={'/consent-signature/:childId/:childContactId/:therapistId'} element={<ConsentSignature />} />
          <Route element={<PrivateComponent />}>
            <Route exact path={'/'} element={<Home />} />
            <Route exact path={'/task'} element={<Task />} />
            <Route exact path={'/child'} element={<Children />} />
            <Route exact path={'/child-detail'} element={<ChildDetail />} />
            <Route exact path={'/session-history'} element={<SessionHistory />} />
            <Route exact path={'/availability'} element={<Availability />} />
            <Route exact path={'/availability-areas'} element={<AvailabilityAreas />} />
            <Route exact path={'/child-form'} element={<ChildForm />} />
            <Route exact path={'/billing'} element={<Billable />} />
            <Route exact path={'/billing/confirmed'} element={<BillingConfirmed />} />
            <Route exact path={'/non/billing'} element={<NonBillable />} />
            <Route exact path={'/billable/detail'} element={<BillableDetail />} />
            <Route exact path={'/therapist/availability'} element={<TherapistAvailability />} />
            <Route exact path={'/therapist/regions'} element={<TherapistRegions />} />
            <Route exact path={'/eims-detail'} element={<EIMSForm />} />
            <Route exact path={'/file/password'} element={<FilePasswordFrom />} />
            <Route exact path={'/file/upload/'} element={<UploadFile />} />
            <Route exact path={'/signature-link'} element={<SendSignatureLink />} />
            <Route exact path={'/form-25'} element={<Form25 />} />
            <Route exact path={'/add-form-25'} element={<CreateForm25 />} />
            <Route exact path={'/form-25/task'} element={<Form25Task />} />
            <Route exact path={'/upload-onboarding/doc'} element={<UploadOnboardingDoc />} />
            <Route exact path={'/documents'} element={<UploadDocument />} />
            <Route exact path={'/fwp-quiz'} element={<FwpQuiz />} />
            <Route exact path={'/universal-quiz'} element={<UniversalQuiz />} />
            <Route exact path={'/quiz-type'} element={<QuizTypes />} />
            <Route exact path={'/eims-task'} element={<EIMSTask />} />
            <Route exact path={'/training-video'} element={<TrainingsVideo />} />
            <Route exact path={'/demo/pdf'} element={<DemoPDF />} />
            <Route exact path={'/child-contacts'} element={<ChildContacts />} />
            <Route exact path={'/parent-contacts'} element={<ParentContactForm />} />
            <Route exact path={'/parent-contacts-task'} element={<ParentContactAttemptTask />} />
            <Route exact path={'/pending-form-25'} element={<DraftForm25 />} />
            <Route exact path={'/session-missing'} element={<SessionMissingTA />} />
            <Route exact path={'/session-issues'} element={<SessionIssues />} />
            <Route exact path={'/session-details'} element={<FilledEIMSForm />} />
            <Route exact path={'/child-consent-contact'} element={<ConsentContacts />} />
            <Route exact path={'/download-form25'} element={<Form25Download />} />
            <Route exact path={'/form13'} element={<Form13Task />} />
            <Route exact path={'/pay-stubb'} element={<PayStub />} />
            <Route exact path={'/unpaid-sessions'} element={<UnpaidSessions />} />
            <Route exact path={'/processed-sessions'} element={<ProcessedSessions />} />
          </Route>
        </Routes>   
     </BrowserRouter>
    </div>
    
  );
}

export default App;
