import React, { useEffect, useState } from "react";
import Header from "./Header";
import { APIEndpoints } from "./APIEndpoints";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import PayStubbFile from "../Assets/pdf/TAPaystubb.pdf";

function PayStub() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [unpaidData, setUnpaidData] = useState({});
  const [processedData, setProcessedData] = useState({});

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const cookie = new Cookies();
      const token = cookie.get("logindata");
      const { access_token, sf_id } = token;
      const response = await axios.get(`${APIEndpoints.PayStubb}${sf_id}`);
      if (response.status === 200) {
        setUnpaidData(response?.data?.unpaid_data);
        setProcessedData(response?.data?.processed_data?.data);
        setIsLoading(false);
      } else {
        toast.success("Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <ToastContainer />
      <Header />
      {isLoading ? (
        <div className="">
          <div className="card-body text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid custom-container my-5">
          <div className="container-fluid my-5 h-100">
            <div>
              <p className="mb-3 font-weight-bold px-2">Unpaid Sessions</p>
            </div>
            {unpaidData?.unpaid_session_count > 0 ? (
              <div
                className="box d-flex justify-content-between p-3 mb-3 rounded"
                style={{ background: "#0A1E4F", color: "white" }}
                onClick={() => {
                  navigate("/unpaid-sessions");
                }}
              >
                <div>
                  <h5>
                    <strong>
                      ${unpaidData?.unpaid_amount} unpaid for{" "}
                      {unpaidData?.unpaid_session_count}{" "}
                      {unpaidData?.unpaid_session_count === 1
                        ? "session"
                        : "sessions"}
                    </strong>
                  </h5>
                  <p>These sessions have not yet been paid</p>
                </div>
                <div className="">
                  <i className="fa-solid fa-arrow-right"></i>
                </div>
              </div>
            ) : (
              <div className="text-center my-4">
                <p>No unpaid sessions found</p>
              </div>
            )}

            <div className="d-flex justify-content-between my-2">
              <p className="mb-3 font-weight-bold px-2">Processed Payments</p>
              {/* <a href={PayStubbFile} download="TA Paystub.pdf">
                <button className="btn btn-outline-danger">
                  <i class="fa fa-long-arrow-down mx-2" aria-hidden="true"></i>{" "}
                  Download All
                </button>
              </a> */}
            </div>

            {Array.isArray(processedData) && processedData.length > 0 ? (
              processedData.map((item, index) => (
                <div
                  className="box d-flex justify-content-between p-3 mb-3 rounded"
                  key={index}
                  onClick={() => {
                    navigate("/processed-sessions", {
                      state: {
                        totalAmount: item?.total_amount,
                        totalSessions: item?.total_sessions,
                        processDate: item?.pay_date,
                        formattedPayDate: item?.formatted_pay_date,
                      },
                    });
                  }}
                >
                  <div>
                    <h5>
                      <strong>
                        ${item?.total_amount} paid for {item?.total_sessions}{" "}
                        {item?.total_sessions === 1 ? "session" : "sessions"}
                      </strong>
                    </h5>
                    <p>Process Date: {item?.formatted_pay_date}</p>
                  </div>
                  <div>
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center my-4">
                <p>No processed payments found</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default PayStub;
